import { createSlice } from '@reduxjs/toolkit'
import { storeToken, removeToken } from 'src/utils/utils'

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    user: null,
    isLoading: false,
    token: 'hey',
    errors: null,
  },
  reducers: {
    login: (state) => {
      state.isLoading = true
    },
    logout: (state) => {
      state.isLoading = true
    },
    loginSuccess: (state, action) => {
      state.isLoading = false
      state.user = action.payload
      state.token = action.payload.data.data.token
      storeToken(action.payload.data.data.token)
    },
    loginFailed: (state, action) => {
      removeToken()
      state.isLoading = false
      state.user = null
      state.token = null
      state.errors = action.payload
    },
    clearError: (state) => {
      state.errors = null
    },
  },
})

export const { login, logout, loginSuccess, loginFailed, clearError } = loginSlice.actions
export default loginSlice.reducer

//export actions (reducers) individually.
//export slice
