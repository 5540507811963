import {createSlice} from '@reduxjs/toolkit'

const projectLogSlice = createSlice({
    name:'projectLog',
    initialState:{
        projectLog:{},
        isLoading:false
    },
    reducers:{
        getLogs: (state) => {
            state.isLoading = true
        },
        getLogsSuccess: (state,action) => {
            state.isLoading = false
            state.projectLog = action.payload
        }
    }
})

export default projectLogSlice.reducer
export const {getLogs, getLogsSuccess} = projectLogSlice.actions