import { createSlice } from '@reduxjs/toolkit'

const projectSlice = createSlice({
  name: 'projects',
  initialState: {
    projects: [],
    pages: [],
    isLoading: true,
    project: {},
    milestones: {},
  },
  reducers: {
    loadProjects: (state, action) => {
      state.isLoading = true
    },
    getProjects: (state, action) => {
      state.projects = action.payload.projects
      state.isLoading = false
    },
    getPages: (state, action) => {
      state.pages = action.payload.pagination
    },
    loadProjectDetails: (state) => {
      state.isLoading = true
    },
    getProjectDetails: (state, action) => {
      state.project = action.payload
    },
    loadProject: (action) => {
      state.projects = action.payload
    },
    loadProjectMilestones: (state) => {
      state.isLoading = true
    },
    getMilestone: (state, action) => {
      state.milestones = action.payload
    },
  },
})

export default projectSlice.reducer
export const {
  loadProjects,
  getProjects,
  getPages,
  getProjectDetails,
  loadProjectDetails,
  loadProject,
  loadProjectMilestones,
  getMilestone,
} = projectSlice.actions
