import { call, put, takeEvery } from 'redux-saga/effects'
import { loginSuccess, loginFailed } from './loginSlice'
import instance from 'src/api/api_instance'

const getResponse = async (action) => {
  const {
    payload: { email, password },
  } = action

  const result = {
    user: null,
    err: null,
  }

  try {
    const response = await instance({
      url: 'login',
      method: 'POST',
      data: {
        email: email,
        password: password,
      },
    })
    if (response) {
      if (response.status === 200) {
        result.user = response
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch (err) {
    result.err = err.response.data.error.message
  }
  return result
}
function* loginValidation(action) {
  const { user, err } = yield call(getResponse, action)
  if (user) {
    yield put(loginSuccess(user))
  } else {
    yield put(loginFailed(err))
  }
}
function* loginSaga() {
  yield takeEvery('login/login', loginValidation)
}

export default loginSaga
