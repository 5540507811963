import { createSlice } from '@reduxjs/toolkit'

const milestoneSlice = createSlice({
  name: 'milestone',
  initialState: {
    milestones: {},
    milestoneDetailsLoading: false,
    showCreateMilestoneModal: false,

  },
  reducers: {
    getMilestone: (state, action) => {
      state.milestones = action.payload
    },
    getMilestoenDetailsSuccess: (state, action) => {
      state.milestones = action.payload
    },
    getMilestoenDetailsFailed: (state, action) => {
      state.milestones = action.payload
    },
    setShowCreateMilestoneModal: (state, action) => {
      state.showCreateMilestoneModal = action.payload
    }

  },
})

export default milestoneSlice.reducer
export const { getMilestoneDetails, getMilestoenDetailsSuccess, getMilestoenDetailsFailed, setShowCreateMilestoneModal } = milestoneSlice.actions
