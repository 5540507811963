import {createSlice} from '@reduxjs/toolkit'

const dashboardSlice = createSlice({
    name:'dashboard',
    initialState:{
        dashboard:{},
        isLoading:false
    },
    reducers:{
        getDashboard: (state) => {
            state.isLoading = true
        },
        getDashboardSuccess: (state,action) => {
            state.isLoading = false
            state.dashboard = action.payload
        }
    }
})

export default dashboardSlice.reducer
export const {getDashboard, getDashboardSuccess} = dashboardSlice.actions