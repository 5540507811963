import { takeEvery, call, put } from "redux-saga/effects";
import instance from "src/api/api_instance";
import { getDeliverablesSuccess, getMembersSuccess, uploadFilesSuccess } from "./deliverablesSlice";

function* fetchChat(action) {
    const {chats, err} = yield call(getChats, action.payload)
    if (chats){
      yield put(getDeliverablesSuccess(chats))
    }
    else console.log(err)
}

const getChats = async (data) => {

    const result = {
      chats: null,
      err: null,
    }
  
    try {
      const response = await instance({
        url: `admin/project/${data.projectId}/${data.role}/${data.roleId}`,
        method: 'GET',
      })

      if (response) {
        if (response.status === 200) {
          result.projects = response.data
        } else {
          result.err = result.err?.response.data.error.message
        }
      }
    } catch (err) {
      result.err = result.err?.response.data.error.message
    }
    return result
  }


function* fetchMembers(action) {
    const {members, err} = yield call(getResponse, action.payload)
    if (members){
      yield put (getMembersSuccess(members))
    }
    else if(err) console.log(err)
}

const getResponse = async () => {
    
    const result = {
      members: null,
      err: null,
    }

    const projectId = window.location.href.split('/')

    try {
      const response = await instance({
        url: `admin/project/${projectId[4]}/members`,
        method: 'GET',
      })
      
      if (response) {
        if (response.status === 200) {
          result.members = response.data
        } else {
          result.err = err.response ? err.response.data.error.message : 'an error'
        }
      }
    } catch (err) {
      result.err = err.response && err.response.data.error.message
    }
    return result
  }

  function* uploadFile (action) {
    const {res, err} = yield call (handleFileUpload, action.payload)
    if(res){
      yield put (uploadFilesSuccess)
    }
    else err && console.log(err)
  }

  const handleFileUpload = async (filexyz) => {
  const projectId = window.location.href.split('/')
    
    const result = {
      res: null,
      err: null,
    }

    const formData = new FormData()
    formData.append('file',filexyz)
    formData.append('project', projectId[4]);
    formData.append('sender', 'me');
    formData.append('receiver', 'you');
    formData.append('sendTo', 'xyz');
    formData.append('sendBy', 'ss');

    console.log(formData.get('file'))
    try {
      const response = await instance({
        url: `admin/deliverable`,
        method: 'POST',
        data:formData,
      })
      if (response) {
        if (response.status === 200) {
          result.members = response.data
        } else {
          result.err = err.response ? err.response.data.error.message : 'an error'
        }
      }
    } catch (err) {
      result.err = err.response && err.response.data.error.message
    }
    return result
  }  

function* deliverablesSaga(){
    yield takeEvery('deliverables/getMembers', fetchMembers)
    yield takeEvery('deliverables/getDeliverables', fetchChat)
    yield takeEvery('deliverables/uploadFiles',uploadFile)
}

export default deliverablesSaga