import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    pages: [],
    isLoading: true,
    sortDirection: {
      name: 'normal',
      status: 'normal',
    },
    filters: {
      role: [],
    },
    keyword: null,
  },
  reducers: {
    loadUsers: (state, action) => {
      state.sortDirection.name = action.payload.sortDirection.name
      state.sortDirection.status = action.payload.sortDirection.status
      state.isLoading = true
    },
    getUsers: (state, action) => {
      state.users = action.payload.data.users
      state.isLoading = false
    },
    getPages: (state, action) => {
      state.pages = action.payload.data.pagination
    },
    loadDelete: (state) => {
      state.isLoading = true
    },
    deleteUsers: (state, action) => {
      const emailID = action.payload
      state.users = state.users.filter((user) => user.id !== emailID)
    },
    createUser: (state) => {
      state.isLoading = true
    },
    createUserSuccess: (state, action) => {
      console.log(action.payload)
    },
    createUserFailed: (state, action) => {
      console.log('failed')
    },
    applyFilters: (state, action) => {
      console.log('applying filters')
      if (state.filters.role.includes(action.payload)) return
      else state.filters.role = [...state.filters.role, action.payload]
    },
    removeFilters: (state, action) => {
      console.log(action.payload)
      if (action.payload === 'empty') {
        state.filters.role = []
      } else state.filters.role = state.filters.role.filter((a) => a !== action.payload)
    },
    updateUser: (state) =>{
      state.isLoading = true
    },
    updateUserSuccess: (state,action) =>{
      console.log('updated')
    }
  },
})

export default userSlice.reducer
export const {
  loadUsers,
  getUsers,
  getPages,
  loadDelete,
  createUser,
  deleteUsers,
  applyFilters,
  removeFilters,
  updateUser,
  updateUserSuccess
} = userSlice.actions
