import { call, put, takeEvery } from 'redux-saga/effects'
import { getProjects, getPages, getProjectDetails, getMilestone } from './projectSlice'
import instance from 'src/api/api_instance'


const getResponse = async (data) => {
  const result = {
    projects: null,
    err: null,
  }

  try {
    const response = await instance({
      url: `admin/projects?page=${data.page}&type=${data.type}&search=${data.search}&limit=${data.limit}`,
      method: 'GET',
    })

    if (response) {
      if (response.status === 200) {
        result.projects = response.data
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch (err) {
    result.err = err.response.data.error.message
  }
  return result
}

function* fetchProjects(action) {
  console.log(action.payload)
  const { projects, err } = yield call(getResponse, action.payload)

  if (projects) {
    yield put(getPages(projects))
    yield put(getProjects(projects))
  } else {
    console.log('Failed to get projects')
    console.log(err)
  }
}

function* projectDetails(action) {
  const { project, err } = yield call(getDetails, action.payload)

  if (project) {
    yield put(getProjectDetails(project))
  } else {
    console.log('Failed to get projects')
    console.log(err)
  }
}

const getDetails = async (data) => {
  const result = {
    project: null,
    err: null,
  }

  try {
    const response = await instance({
      url: `admin/projects/${data}`,
      method: 'GET',
    })

    if (response) {
      if (response.status === 200) {
        result.project = response.data
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch (err) {
    result.err = err?.response.data.error
  }
  return result
}

function* projectMilestone(action) {
  const { milestones, err } = yield call(getMilestones, action.payload)
  if (milestones) {
    yield put(getMilestone(milestones))
  } else {
    console.log('Failed to get project milestones')
    console.log(err)
  }
}

const getMilestones = async (data) => {
  // console.log(data)
  const result = {
    milestone: null,
    err: null,
  }

  try {
    const response = await instance({
      url: `admin/projects/${data}/milestones`,
      method: 'GET',
    })

    if (response) {
      if (response.status === 200) {
        result.milestone = response.data
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch (err) {
    result.err = err.response.data.error.message
  }
  return result
}

function* projectSaga() {
  yield takeEvery('projects/loadProjects', fetchProjects)
  yield takeEvery('projects/loadProjectDetails', projectDetails)
  yield takeEvery('projects/loadProjectMilestones', projectMilestone)
}

export default projectSaga
