import instance from "src/api/api_instance";
import { getDashboardSuccess } from "./dashboardSlice";

const { takeEvery, call, put } = require("redux-saga/effects");

function* fetchDashboard () {
    const {dashboard, err} = yield call (getResponse)
    if(dashboard)
    {
      yield put(getDashboardSuccess(dashboard))
    }
    else console.log(err)
}

const getResponse = async () => {
    const result = {
      dashboard: null,
      err: null,
    }
  
    try {
      const response = await instance({
        url: `admin/dashboard`,
        method: 'GET',
      })
      if (response) {
        if (response.status === 200) {
          result.dashboard = response.data
        } else {
          result.err = err.response ? err.response.data.error.message : 'an error'
        }
      }
    } catch (err) {
      result.err = err.response ? err.response.data.error.message : 'an error occurred'
    }
    return result
  }


function* dashboardSaga() {
    yield takeEvery('dashboard/getDashboard', fetchDashboard)
}

export default dashboardSaga