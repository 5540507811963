import { Navigate } from 'react-router-dom'
import { checkStore } from 'src/utils/utils'

const RestrictedRoutes = ({ children }) => {
  const user = checkStore()
  if (user) {
    return <Navigate to="/dashboard" />
  }
  return children
}

export default RestrictedRoutes
