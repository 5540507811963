import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { getUsers, getPages, updateUserSuccess} from './userSlice'
import instance, { setAuthToken } from 'src/api/api_instance'

const getResponse = async (data) => {
  const result = {
    user: null,
    err: null,
  }

  setAuthToken()

  try {
    const response = await instance({
      url: `admin/users?page=${data.page}`,
      method: 'GET',
    })

    if (response) {
      if (response.status === 200) {
        result.user = response
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch (err) {
    result.err = err.response.data.error.message
  }
  return result
}

function* fetchUsers(action) {
  const { user, err } = yield call(getResponse, action.payload)
  if (user) {
    yield put(getPages(user))
    yield put(getUsers(user))
  } else {
    console.log('failed to get users')
    console.log(err)
  }
}

function* deleteUser(action) {
  const { user, err } = yield call(handleDeleteUser, action.payload)
  if (user) {
    yield put(getPages(user))
    yield put(getUsers(user))
  } else {
    console.log('failed to get users')
    console.log(err)
  }
}

const handleDeleteUser = async (action) => {
  const result = {
    res: null,
    err: null,
  }

  const data = {
    user_email: action,
  }

  try {
    const response = await instance({
      url: `admin/users`,
      method: 'DELETE',
      data: data,
    })
    if (response) {
      console.log(response)
      if (response.status === 200) {
        result.res = response
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch {
    result.err = err.response.data.error.message
  }
  return result
}

function* createUser(action) {
  yield call(handleCreateUser, action.payload)
}

const handleCreateUser = async (action) => {
  console.log(action)
  const data = {
    firstName: action.firstName,
    lastName: action.lastName,
    email: action.email,
    mobileNumber: action.mobileNumber,
    password: action.password,
    linkedInUser: action.linkedInUser,
    termsAndCondition: action.termsAndCondition,
  }
  try {
    const response = await instance({
      url: `users`,
      method: 'POST',
      data: data,
    })
    if (response) {
      console.log(response)
      if (response.status === 200) {
        result.res = response
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch (err) {
    result.err = err.response.data.error.message
  }
}

function* updateUser(action){
  const { user, err } = yield call(handleUpdateUser, action.payload)
  if(user){
  console.log('success')
  }
    else console.log('failed')
}

const handleUpdateUser = async (data) => {
  console.log(data)
  const result = {
    milestone: null,
    err: null,
  }

  try {
    const response = await instance({
      url: `admin/users/${data.userId}`,
      method: 'PATCH',
    })

    if (response) {
      if (response.status === 200) {
        result.milestone = response.data
      } else {
        result.err = err.response.data.error.message
      }
    }
  } catch (err) {
    result.err = err.response.data.error.message
  }
  return result
}




function* userSaga() {
  yield takeEvery('users/loadUsers', fetchUsers)
  yield takeEvery('users/loadDelete', deleteUser)
  yield takeLatest('users/createUser', createUser)
  yield takeLatest('users/updateUser', updateUser)
}

export default userSaga
