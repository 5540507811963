import { takeEvery,put,call } from "redux-saga/effects"
import { getLogsSuccess } from "./projectLogSlice"
import instance from "src/api/api_instance"

function* fetchLogs(action){
    const {logs, err} = yield call (getResponse,action.payload)
    if(logs)
    {
      yield put(getLogsSuccess(logs))
    }
    else console.log(err)
}

const getResponse = async (data) => {
    const result = {
      logs: null,
      err: null,
    }
  
    try {
      const response = await instance({
        url: `admin/project/${data}/logs`,
        method: 'GET',
      })
      if (response) {
        if (response.status === 200) {
          result.logs = response.data
        } else {
          result.err = err.response ? err.response.data.error.message : 'an error'
        }
      }
    } catch (err) {
      result.err = err.response ? err.response.data.error.message : 'an error occurred'
    }
    return result
  }


function* projectLogSaga(){
    yield takeEvery('projectLog/getLogs', fetchLogs)
}

export default projectLogSaga