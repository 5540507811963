import { createSlice } from "@reduxjs/toolkit";

const deliverablesSlice = createSlice({
    name: 'deliverables',
    initialState:{
        members:[],
        isLoading:false,
        deliverables:{}
    },
    reducers: {
        getMembers: (state) =>{
            state.isLoading = true
        },
        getMembersSuccess: (state, action) => {
            state.members = action.payload;
        },
        getDeliverables: (state) =>{
            state.isLoading = true
        },
        getDeliverablesSuccess: (state, action) => {
            state.isLoading = false
            state.deliverables = action.payload
            console.log(action.payload)
        },
        uploadFiles: (state) =>{
            state.isLoading = true
        },
        uploadFilesSuccess: (state) =>{
            state.isLoading = false
        }
    }
})

export default deliverablesSlice.reducer
export const {getMembersSuccess, getDeliverables, getDeliverablesSuccess, getMembers, uploadFiles, uploadFilesSuccess} = deliverablesSlice.actions