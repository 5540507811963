import { configureStore, applyMiddleware } from '@reduxjs/toolkit'
import loginSlice from './redux-toolkit/features/login/loginSlice'
import createSagaMiddleware from 'redux-saga'
import userSlice from './redux-toolkit/features/users/userSlice'
import rootSaga from './redux-toolkit/rootSaga/rootSaga'
import projectSlice from './redux-toolkit/features/projects/projectSlice'
import milestoneSlice from "./redux-toolkit/features/milestone/milestoneSlice";
import deliverablesSlice from './redux-toolkit/features/deliverables/deliverablesSlice'
import dashboardSlice from './redux-toolkit/features/dashboard/dashboardSlice'
import projectLogSlice from './redux-toolkit/features/logs/projectLogSlice'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer: {
    user: loginSlice,
    users: userSlice,
    projects: projectSlice,
    milestone: milestoneSlice,
    deliverables: deliverablesSlice,
    dashboard: dashboardSlice,
    projectLogs: projectLogSlice,
  },
  middleware: [sagaMiddleware],
})

sagaMiddleware.run(rootSaga)

export default store
