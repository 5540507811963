import { Navigate } from 'react-router-dom'
import { checkStore } from 'src/utils/utils'

const ProtectedRoutes = ({ children }) => {
  const user = checkStore()
  if (!user) {
    return <Navigate to="/login" />
  }
  return children
}

export default ProtectedRoutes
