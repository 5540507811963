import axios from 'axios'
import { checkStore } from 'src/utils/utils'

const baseURL = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
      return process.env.REACT_APP_API_URL_LOCAL
    case 'development':
      return process.env.REACT_APP_API_URL_DEVELOPMENT
    case 'staging':
      return process.env.REACT_APP_API_URL_STAGING
    case 'production':
      return process.env.REACT_APP_API_URL_PRODUCTION
  }
}

const instance = axios.create({
  baseURL: baseURL(),
  headers: {
    'Content-Type': 'application/json',
    timeout: 1000,
    Authorization: `${checkStore()}`,
  },
})

export const setAuthToken = () => {
  const token = checkStore()
  if (token) {
    instance.defaults.headers['Authorization'] = token
  } else return
}

export default instance
