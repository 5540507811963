import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ProtectedRoutes from './routes/ProtectedRoutes'
import RestrictedRoutes from './routes/RestrictedRoutes'
import { useSelector } from 'react-redux'

import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const user = useSelector((state) => state.user.user)

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route
            exact
            path="/login"
            name="login"
            element={
              <RestrictedRoutes user={user}>
                <Login />
              </RestrictedRoutes>
            }
          />
          <Route
            exact
            path="/register"
            name="register"
            element={
              <RestrictedRoutes user={user}>
                <Register />
              </RestrictedRoutes>
            }
          />
          <Route
            path="*"
            name="Home"
            element={
              <ProtectedRoutes user={user}>
                <DefaultLayout />
              </ProtectedRoutes>
            }
          />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
