export function storeToken(token) {
  localStorage.setItem('jwt', token)
  window.location.href='/'
}

export function removeToken() {
  localStorage.removeItem('jwt')
}

export function checkStore() {
  return localStorage.getItem('jwt')
  
}
