import { all } from 'redux-saga/effects'
import loginSaga from '../features/login/loginSaga'
import userSaga from '../features/users/userSaga'
import projectSaga from '../features/projects/projectSaga'
import deliverablesSaga from '../features/deliverables/deliverablesSaga'
import dashboardSaga from '../features/dashboard/dashboardSaga'
import projectLogSaga from '../features/logs/projectLogSaga'

function* watchAll() {
  yield all([
    loginSaga(),
    userSaga(),
    projectSaga(),
    deliverablesSaga(),
    dashboardSaga(),
    projectLogSaga()
  ])
}

export default watchAll
